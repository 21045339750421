import { resolveScorePool } from "./resolveScorePool";
async function resolveNoneComputedStyle(element, property) {
  const scores = await resolveScorePool(element, property);
  return scores === null || scores === void 0 ? void 0 : scores[0].style;
}
export * from "./calculateInlineScore";
export * from "./calculateScore";
export * from "./iterateRules";
export * from "./iterateStylesheets";
export * from "./matchingSelector";
export * from "./priorityByRule";
export * from "./sortScores";
export * from "./resolveScorePool";
export { resolveNoneComputedStyle };