/**
 * Wait for a given object which is calculated through a callback.
 */
async function waitObject(getObject) {
  let delayNextCheck = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
  let maxTries = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  let i = 0;
  while (!getObject()) {
    if (maxTries > 0 && i >= maxTries) {
      return undefined;
    }
    await new Promise(r => setTimeout(r, delayNextCheck));
    i++;
  }
  return getObject();
}
export { waitObject };