import { getPreviousVisibleElement } from "../utils";
import { HTML_ATTRIBUTE_THUMBNAIL, HTML_ATTRIBUTE_BLOCKER_CONNECTED, HTML_ATTRIBUTE_BLOCKER_ID } from "..";
function isHtmlNodeContentBlocker(node) {
  return node.hasAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED);
}
function mapIgnoreInvisibleAndUsePreviousVisible(element) {
  return element.offsetParent ? element : getPreviousVisibleElement(element, isHtmlNodeContentBlocker);
}

/**
 * Find a duplicate before a given node to avoid duplicate content blockers for the same content.
 */
function findPreviousVisibleDuplicate(createBefore, node, blockerId) {
  var _createBefore$parentE, _createBefore$parentE2, _createBefore$parentE3, _secondParentPrevious;
  const {
    previousElementSibling
  } = createBefore;
  const visualThumbnail = node.getAttribute(HTML_ATTRIBUTE_THUMBNAIL);
  const firstParentPreviousElementSibling = (_createBefore$parentE = createBefore.parentElement) === null || _createBefore$parentE === void 0 ? void 0 : _createBefore$parentE.previousElementSibling;
  const secondParentPreviousElementSibling = (_createBefore$parentE2 = createBefore.parentElement) === null || _createBefore$parentE2 === void 0 ? void 0 : (_createBefore$parentE3 = _createBefore$parentE2.parentElement) === null || _createBefore$parentE3 === void 0 ? void 0 : _createBefore$parentE3.previousElementSibling;
  const possibleDuplicates = [getPreviousVisibleElement(createBefore, isHtmlNodeContentBlocker), previousElementSibling, previousElementSibling === null || previousElementSibling === void 0 ? void 0 : previousElementSibling.lastElementChild,
  // 1st parent up
  firstParentPreviousElementSibling, firstParentPreviousElementSibling === null || firstParentPreviousElementSibling === void 0 ? void 0 : firstParentPreviousElementSibling.lastElementChild,
  // 2nd parent up
  secondParentPreviousElementSibling, secondParentPreviousElementSibling === null || secondParentPreviousElementSibling === void 0 ? void 0 : secondParentPreviousElementSibling.lastElementChild, secondParentPreviousElementSibling === null || secondParentPreviousElementSibling === void 0 ? void 0 : (_secondParentPrevious = secondParentPreviousElementSibling.lastElementChild) === null || _secondParentPrevious === void 0 ? void 0 : _secondParentPrevious.lastElementChild].filter(Boolean)
  // Get previous visible element if the last child element is not visible
  .map(mapIgnoreInvisibleAndUsePreviousVisible).filter(Boolean);
  for (const possibleDuplicate of possibleDuplicates) {
    if (+possibleDuplicate.getAttribute(HTML_ATTRIBUTE_BLOCKER_ID) === blockerId && possibleDuplicate.hasAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED)) {
      const connected = +possibleDuplicate.getAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED);

      // If the blocked node has a visual thumbnail, check if the blocked content of
      // the possible duplicate has not the same visual thumbnail. Otherwise, use it
      // as duplicate.
      const blockedContentOfPossibleDuplicate = document.querySelector(`[${HTML_ATTRIBUTE_BLOCKER_CONNECTED}="${connected}"]:not(.rcb-content-blocker)`);
      if (visualThumbnail && blockedContentOfPossibleDuplicate !== null && blockedContentOfPossibleDuplicate !== void 0 && blockedContentOfPossibleDuplicate.hasAttribute(HTML_ATTRIBUTE_THUMBNAIL) && blockedContentOfPossibleDuplicate.getAttribute(HTML_ATTRIBUTE_THUMBNAIL) !== visualThumbnail) {
        return false;
      }
      return possibleDuplicate;
    }
  }
  return false;
}
export { findPreviousVisibleDuplicate };