/**
 * Ensure the given HTML is a child of `body`. If not, it automatically gets moved.
 */
function ensureBodyElement(element) {
  const {
    body
  } = document;
  const {
    parentElement
  } = element;
  if (parentElement !== body) {
    body.appendChild(element);
  }
  return element;
}
export { ensureBodyElement };