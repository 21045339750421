import { calculateScore } from ".";
import { matchingSelector } from ".";
const DEFAULT_SCORE_POOL_MAIN_THREAD_YIELD = 15;
async function iterateRules(rules, element, scoresPool, property) {
  for (const ruleIdx in rules) {
    const rule = rules[ruleIdx];
    if (!(rule instanceof CSSStyleRule)) {
      continue;
    }
    const now = performance.now();
    if (scoresPool.calculationTime >= DEFAULT_SCORE_POOL_MAIN_THREAD_YIELD) {
      await new Promise(r => setTimeout(r, 0));
      scoresPool.calculationTime = 0;
    }
    try {
      if (matchingSelector(element, rule.selectorText)) {
        const style = rule.style[property];
        if (style !== undefined && style !== "") {
          const {
            items
          } = scoresPool;
          items.push({
            ...calculateScore(rule, items.length, property),
            style
          });
        }
      }
    } catch (e) {
      // Silence is golden.
    }
    scoresPool.calculationTime += performance.now() - now;
  }
}
export { iterateRules };