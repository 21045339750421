import { HTML_ATTRIBUTE_COOKIE_IDS, HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_BY, OPT_IN_CONTENT_BLOCKER_ALL, HTML_ATTRIBUTE_CONFIRM, HTML_ATTRIBUTE_DELEGATE_CLICK } from "@devowl-wp/headless-content-unblocker";
import { unblockSync } from ".";
/**
 * `window.consentApi.unblock`: Check if a given URL / string is blocked by a Content Blocker:
 *
 * - When a Content Blocker exists, the Promise is only resolved after given consent
 * - When no Content Blocker exists, the Promise is immediate resolved
 *
 * Example (ES5):
 * ```js
 * (window.consentApi && window.consentApi.unblock("player.vimeo.com") || Promise.resolve()).then(function() {
 *     console.log("Consent for Vimeo given, unlock content...!");
 * });
 * ```
 *
 * Example (ES6, Babel, TS):
 * ```js
 * (window.consentApi?.unblock("player.vimeo.com") || Promise.resolve()).then(() => {
 *     console.log("Consent for Vimeo given, unlock content...!");
 * });
 * ```
 *
 * @param url
 * @param ref Create a visual content blocker to a given HTML element
 */
function unblock(blocker, url, options) {
  return new Promise(resolve => {
    const {
      ref,
      attributes = {},
      confirm
    } = options instanceof HTMLElement ? {
      ref: options
    } : options || {
      ref: document.createElement("div")
    };
    if (confirm) {
      Object.assign(attributes, {
        [HTML_ATTRIBUTE_CONFIRM]: true,
        [HTML_ATTRIBUTE_DELEGATE_CLICK]: JSON.stringify({
          selector: "self"
        })
      });
    }

    // Find matching blocker
    const isPseudoRef = !ref.parentElement;
    const found = unblockSync(blocker, url);
    if (found) {
      // Add attributes to DOM element so it can be consumed by `manipulateDom`
      ref.setAttribute(HTML_ATTRIBUTE_BY, "services");
      ref.setAttribute(HTML_ATTRIBUTE_COOKIE_IDS, found.services.join(","));
      ref.setAttribute(HTML_ATTRIBUTE_BLOCKER_ID, found.id.toString());
      for (const k in attributes) {
        const v = attributes[k];
        ref.setAttribute(k, typeof v === "object" ? JSON.stringify(v) : v);
      }
      ref.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, () => {
        resolve();
      });

      // Append blocking element to HTML DOM if not already exists
      if (isPseudoRef) {
        document.body.appendChild(ref);
      }
    } else {
      resolve();
    }
  });
}
export { unblock };