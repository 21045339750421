const MEMORIZE_NATIVE_EVENT_PROPERTY = "rcbNativeEventListenerMemorize";

/**
 * We listen to the passed event and once that event got triggered, all event registrations
 * afterwards are automatically executed, too. This does not need another trigger again.
 *
 * You need to call this function as early as possible to catch all triggers.
 */
function memorizeNativeEvent(element, eventName) {
  const memorizeProp = `${MEMORIZE_NATIVE_EVENT_PROPERTY}_${eventName}`;
  Object.assign(element, {
    [memorizeProp]: new Promise(resolve => element.addEventListener(eventName, resolve))
  });
}
export { memorizeNativeEvent, MEMORIZE_NATIVE_EVENT_PROPERTY };