import { HTML_ATTRIBUTE_INLINE_STYLE, HTML_TAG_CONSENT_SCRIPT } from ".";

/**
 * Put an prepared inline style (tag containing a `consent-inline-style` attribute) to the DOM.
 */
async function putStyleInlineToDom(node) {
  // Get the inline style itself
  const inlineStyle = node.getAttribute(HTML_ATTRIBUTE_INLINE_STYLE);
  node.removeAttribute(HTML_ATTRIBUTE_INLINE_STYLE);

  // Transform the tag to the `style`
  // We need to make such a hacky way because regex does not work and `outerHTML` has also some issues...
  let inlineStyleHtml = node.outerHTML.substr(HTML_TAG_CONSENT_SCRIPT.length + 1);
  inlineStyleHtml = inlineStyleHtml.substr(0, inlineStyleHtml.length - HTML_TAG_CONSENT_SCRIPT.length - 3);
  inlineStyleHtml = inlineStyleHtml.replace(new RegExp(`type="application/consent"`), "");
  inlineStyleHtml = `<style ${HTML_ATTRIBUTE_INLINE_STYLE}="1" ${inlineStyleHtml}${inlineStyle}</style>`; // add inline attribute so `applyStyleRules` can check one more time for blocked URLs

  node.parentElement.replaceChild(new DOMParser().parseFromString(inlineStyleHtml, "text/html").querySelector("style"), node);
}
export { putStyleInlineToDom };