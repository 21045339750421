import { calculateInlineScore, iterateStylesheets, sortScores } from ".";
async function resolveScorePool(element, property) {
  const scores = {
    calculationTime: 0,
    items: []
  };

  // Iterate all our stylesheets
  await iterateStylesheets(element, scores, property);

  // Calculate by inline style
  const inline = calculateInlineScore(element, property);
  const {
    items
  } = scores;
  inline && items.push(inline);

  // Prepare result
  if (!items.length) {
    return undefined;
  }

  // Sort scores
  sortScores(items);
  return items;
}
export { resolveScorePool };