import { batchRequest, waitForValidLogin } from "./";
import { parseResult, nonceDeprecationPool, applyQueryString, commonUrlBuilder, addCorruptRestApi, addCorruptRestApiLog, removeCorruptRestApi } from ".";
import deepMerge from "deepmerge";
import jsonFormData from "json-form-data";
import { ERouteHttpVerb } from "@devowl-wp/api";
const CONTENT_TYPE_JSON = "application/json;charset=utf-8";

// Fix: Return type of exported function has or is using name 'FormatOptions'
// from external module "/home/mg/vscode-workspace/devowl-wp/node_modules/@types/json-form-data/index" but cannot be named.
// type MultiPartOptions = Parameters<typeof jsonFormData>[1];

/**
 * Build and execute a specific REST query.
 *
 * @see urlBuilder
 * @returns Result of REST API
 * @throws
 */
async function commonRequest(_ref) {
  let {
    location,
    options,
    request: routeRequest,
    params,
    /**
     * Settings for the `window.fetch` call.
     */
    settings = {},
    cookieValueAsParam,
    multipart = false,
    sendRestNonce = true,
    replayReason: happenedReplayReason,
    /**
     * See `batchRequest` for more information.
     *
     * @see https://make.wordpress.org/core/2020/11/20/rest-api-batch-framework-in-wordpress-5-6/
     */
    allowBatchRequest
  } = _ref;
  const namespace = location.namespace || options.restNamespace;
  const url = commonUrlBuilder({
    location,
    params,
    nonce: false,
    options,
    cookieValueAsParam
  });

  // Use global parameter (see https://developer.wordpress.org/rest-api/using-the-rest-api/global-parameters/)
  if (["wp-json/", "rest_route="].filter(s => url.indexOf(s) > -1).length > 0 && location.method && location.method !== ERouteHttpVerb.GET) {
    settings.method = ERouteHttpVerb.POST;
  } else {
    settings.method = location.method || ERouteHttpVerb.GET;
  }

  // Request with GET/HEAD method cannot have body
  const apiUrl = new URL(url, window.location.href);
  const allowBody = ["HEAD", "GET"].indexOf(settings.method) === -1;
  if (!allowBody && routeRequest) {
    applyQueryString(apiUrl, [routeRequest], true);
  }
  const apiUrlBuilt = apiUrl.toString();

  // Determine body
  let body;
  if (allowBody) {
    if (multipart) {
      // Let's create a multipart request...
      body = jsonFormData(routeRequest, typeof multipart === "boolean" ? {} : multipart);

      // Check if one of the form data is a blob and if not, revert back to JSON
      const hasBlob = Array.from(body.values()).filter(v => v instanceof File).length > 0;
      if (!hasBlob) {
        body = JSON.stringify(routeRequest);
      }
    } else {
      // It is a usual JSON request, we do not need to send a multipart request
      body = JSON.stringify(routeRequest);
    }
  }

  // Do the request
  const restNonce = await nonceDeprecationPool(options.restNonce);
  const hasRestNonce = typeof restNonce !== "undefined";
  const init = deepMerge.all([settings, {
    headers: {
      ...(typeof body === "string" ? {
        "Content-Type": CONTENT_TYPE_JSON
      } : {}),
      ...(hasRestNonce && sendRestNonce ? {
        "X-WP-Nonce": restNonce
      } : {}),
      Accept: "application/json, */*;q=0.1"
    }
  }], {
    // Do not override e.g. `AbortSignal` instances
    isMergeableObject: value => Object.prototype.toString.call(value) === "[object Object]"
  });
  init.body = body; // Do not make body merge-able

  if (allowBatchRequest && location.method !== ERouteHttpVerb.GET && !(body instanceof FormData)) {
    return batchRequest({
      method: location.method,
      path: commonUrlBuilder({
        location,
        params,
        nonce: false,
        options: {
          ...options,
          restRoot: "https://a.de/wp-json"
        },
        cookieValueAsParam
      }).substring(20),
      body: routeRequest
    }, {
      ...options,
      signal: settings.signal,
      ...(typeof allowBatchRequest === "boolean" ? {} : allowBatchRequest)
    });
  }
  let result;

  // Detect page hide of browser which can lead to cancelled requests which throw an error.
  // In this case, we should not show the notice (see CU-33tce0y).
  let pageUnload = false;
  const pageUnloadListener = () => {
    pageUnload = true;
  };
  window.addEventListener("pagehide", pageUnloadListener);
  window.addEventListener("beforeunload", pageUnloadListener);
  const start = new Date().getTime();
  let ms;
  try {
    result = await window.fetch(apiUrlBuilt, init);
    ms = new Date().getTime() - start;
    removeCorruptRestApi(namespace);
  } catch (e) {
    // window.fetch does not throw by default, so there must be an error with the network or Ad-blocker
    ms = new Date().getTime() - start;
    if (!pageUnload) {
      addCorruptRestApiLog({
        method: location.method,
        route: apiUrl.pathname,
        ms,
        response: `${e}`
      });
      addCorruptRestApi(settings, namespace);
    }
    console.error(e);
    throw e;
  } finally {
    window.removeEventListener("pagehide", pageUnloadListener);
    window.removeEventListener("beforeunload", pageUnloadListener);
  }

  // `window.fetch` does not throw an error if the server response an error code.
  if (!result.ok) {
    let responseJSON = undefined;
    let replay = false;
    let replayReason;
    try {
      responseJSON = await parseResult(apiUrlBuilt, result, location.method);

      // wordpress.com private site compatibility
      /* istanbul ignore if */
      if (responseJSON.code === "private_site" && result.status === 403 && hasRestNonce && !sendRestNonce) {
        replay = true;
        replayReason = 1;
      }

      // Refresh nonce automatically
      /* istanbul ignore if */
      if (responseJSON.code === "rest_cookie_invalid_nonce" && hasRestNonce) {
        const {
          restRecreateNonceEndpoint
        } = options;
        try {
          replay = true;
          if (happenedReplayReason === 2) {
            // We recreated the nonce previously, but it failed again. The login might be outdated.
            // See also https://github.com/WordPress/gutenberg/issues/13509#issuecomment-1563964440
            replayReason = 4;
            await waitForValidLogin();
          } else {
            replayReason = 2;
          }
          await nonceDeprecationPool(restNonce, restRecreateNonceEndpoint);
        } catch (e) {
          // Silence is golden.
        }
      }

      // Support retry-after header (https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Retry-After)
      // Currently, only `<delay-seconds>` value is respected, not a Date yet
      const retryAfter = result.headers.get("retry-after");
      if (retryAfter.match(/^\d+$/)) {
        replay = +retryAfter * 1000;
        replayReason = 3;
      }
    } catch (e) {
      // Silence is golden.
    }
    if (replay) {
      const replayData = {
        location,
        options,
        multipart,
        params,
        request: routeRequest,
        sendRestNonce: true,
        settings,
        replayReason
      };
      if (typeof replay === "number") {
        return new Promise(resolve => setTimeout(() => commonRequest(replayData).then(resolve), replay));
      } else {
        return await commonRequest(replayData);
      }
    }
    addCorruptRestApiLog({
      method: location.method,
      route: apiUrl.pathname,
      ms,
      response: JSON.stringify(responseJSON)
    });
    addCorruptRestApi(settings);
    const resultAny = result;
    resultAny.responseJSON = responseJSON;
    throw resultAny;
  }
  return parseResult(apiUrlBuilt, result, location.method);
}
export { commonRequest };