/**
 * For a list of unblocked nodes, check if it are a list of `<source`s and load the parent `<video`.
 *
 * @see https://stackoverflow.com/a/18454389/5506547
 */
function loadVideoSource(unblockedNodes) {
  const foundVideos = unblockedNodes.filter(_ref => {
    let {
      node: {
        nodeName,
        parentElement
      }
    } = _ref;
    return nodeName === "SOURCE" && parentElement.nodeName === "VIDEO";
  }).map(_ref2 => {
    let {
      node: {
        parentElement
      }
    } = _ref2;
    return parentElement;
  });

  // Unique it to avoid multiple load's
  foundVideos.filter((value, index) => foundVideos.indexOf(value) === index).forEach(video => video.load());
}
export { loadVideoSource };