import { HTML_ATTRIBUTE_COOKIE_IDS, HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_BY, HTML_TAG_CONSENT_SCRIPT } from "../dom";
import { decideToUnblock } from ".";
const FIND_BLOCKED_NODES_PRIORITY_SCRIPT = 10;
const FIND_BLOCKED_NODES_PRIORITY_NON_SCRIPT = 0;

/**
 * Read all HTML elements and check, if they can be enabled by a consent.
 */
function findBlockedNodes(checker) {
  const result = [];
  const nodes = Array.prototype.slice.call(document.querySelectorAll(`[${HTML_ATTRIBUTE_COOKIE_IDS}]`));
  for (const node of nodes) {
    const {
      blocker,
      consent
    } = decideToUnblock(node.getAttribute(HTML_ATTRIBUTE_BY), node.getAttribute(HTML_ATTRIBUTE_COOKIE_IDS), +node.getAttribute(HTML_ATTRIBUTE_BLOCKER_ID), checker);
    const isVisualCb = node.className.indexOf("rcb-content-blocker") > -1;
    result.push({
      node,
      consent,
      isVisualCb,
      blocker,
      priority: node.tagName.toLowerCase() === HTML_TAG_CONSENT_SCRIPT ? FIND_BLOCKED_NODES_PRIORITY_SCRIPT : FIND_BLOCKED_NODES_PRIORITY_NON_SCRIPT
    });
  }

  // Sort blockable nodes by priority, e.g. always execute scripts at the end
  result.sort((_ref, _ref2) => {
    let {
      priority: a
    } = _ref;
    let {
      priority: b
    } = _ref2;
    return a - b;
  });
  return result;
}
export { findBlockedNodes, FIND_BLOCKED_NODES_PRIORITY_NON_SCRIPT, FIND_BLOCKED_NODES_PRIORITY_SCRIPT };