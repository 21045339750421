import { findAndUnblock } from ".";

/**
 * Use this class as main entry for your unblocking mechanism.
 *
 * **Attention:** Please make sure you start the watcher at time where the `document.readyState`
 * is not already `complete`. Why? At this time, we can make sure that our `jQuery.fn.ready` and
 * other initiators works as expected.
 */
class UnblockWatcher {
  constructor(options) {
    this.nextTimeout = void 0;
    this.startTimeout = void 0;
    this.options = void 0;
    this.options = options;
  }
  unblockNow() {
    return findAndUnblock(this.options);
  }
  start() {
    let mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "unblock";
    this.setMode(mode);
    this.stop();
    this.startTimeout = setTimeout(this.doTimeout.bind(this), 0);
  }
  doTimeout() {
    clearTimeout(this.nextTimeout);
    this.unblockNow();
    this.nextTimeout = setTimeout(this.doTimeout.bind(this), 1000);
  }
  stop() {
    clearTimeout(this.nextTimeout);
    clearTimeout(this.startTimeout);
  }
  setMode(mode) {
    this.options.mode = mode;
  }
}
export { UnblockWatcher };