import { getOptionsFromWindow } from "./getOptionsFromWindow";

/**
 * Use this if you do not have the Mobx store available in your JavaScript asset.
 * E. g. the `banner.js` does not rely on the mobx store and should access the
 * localized options via `window.realCookieBanner.others` directly.
 */
function getOtherOptionsFromWindow() {
  return getOptionsFromWindow().others;
}
export { getOtherOptionsFromWindow };